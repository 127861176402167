import React from 'react';
import Content from './Content';
import Toolbar from '../Toolbar/Toolbar';
import Backdrop from '../Backdrop/Backdrop';
import "./MainLayout.css";
import LandingFooter from '../LandingFooter/LandingFooter'


class MainLayout extends React.Component {


  render() {



    const { children } = this.props;
    return (
      <main>
        <Content fluid onClick={this.handleContentClick}>
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
            <div className="mainLayout">
             {children}
            </div>
            <LandingFooter />
        </Content>
      </main>
    );
  }
}

export default MainLayout;