import examReducer from "./exam";
import examsReducer from "./exams";
import coursesReducer from "./courses";
import reportReducer from "./report";
import contactReducer from "./contact";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    exam: examReducer,
    courses: coursesReducer,
    exams: examsReducer,
    contact: contactReducer,
    report: reportReducer
});

export default allReducers;
