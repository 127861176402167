import React, { Component } from "react";
import './LandingFooter.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons"


class LandingFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShown: 0,
    }
    this.setIsShown = this.setIsShown.bind(this);
  }

  setIsShown(value){
    this.setState({
      isShown: value
    })
  }

  render() {
    return (
      <footer>
      	<div className="landingfooter">
      		<div className="baseStyling">
      			<div className="bottomLinks"> 
      				<a href="https://geertdepont.com" rel="noopener noreferrer" target="_blank" className="footerLink LinkStyle" > 
	 					GEERT DE PONT
					</a>
      				 <br/> <br/>
      				<div>
	      				<a href="https://www.facebook.com/GeertcdePont" rel="noopener noreferrer" target="_blank" className="footerLink LinkStyle" > 
    		 					<FontAwesomeIcon className="fa-fw fa-2x" icon={faFacebook} />
    						</a>
	      				<a href="https://www.instagram.com/geertdepont/" rel="noopener noreferrer" target="_blank" className="footerLink LinkStyle" > 
    		 					<FontAwesomeIcon className="fa-fw fa-2x" icon={faInstagram} />
    						</a>
	      				<a href="https://www.linkedin.com/in/geert-de-pont-5362b914b/" rel="noopener noreferrer" target="_blank" className="footerLink LinkStyle" > 
    		 					<FontAwesomeIcon className="fa-fw fa-2x" icon={faLinkedin} />
    						</a>
    	      		<a href="https://www.youtube.com/channel/UCiykEPx7sWdKBS-uT7z2kSw" rel="noopener noreferrer" target="_blank" className="footerLink LinkStyle" > 
    		 					<FontAwesomeIcon className="fa-fw fa-2x" icon={faYoutube} />
    						</a>
					     </div>
               <br/>
               
      	     </div>

	        </div>
        </div>
      </footer>
    );
  }
}

export default LandingFooter;
