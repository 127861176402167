import React, { Component } from 'react';
import './App.css';
import MainLayout from '../components/Layout/MainLayout';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";


// import HomePage from '../components/Pages/HomePage';
// import ContactPage from '../components/Pages/ContactPage';
// import LearnDutch from '../components/Pages/LearnDutch';
// import Course from '../components/Pages/Course';
// import NoMatchPage from '../components/Pages/NoMatchPage';
const HomePage = React.lazy(() => import('../components/Pages/HomePage'));
const CancelPage = React.lazy(() => import('../components/Pages/CancelPage'));
const SuccessPage = React.lazy(() => import('../components/Pages/SuccessPage'));
const NoMatchPage = React.lazy(() => import('../components/Pages/NoMatchPage'));

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<div className="loading" ></div>}>
                <Route exact path="/" >
                  <HomePage />
                </Route>
                <Route path="/cancel">
                  <CancelPage />
                </Route>
                <Route path="/success">
                  <SuccessPage />
                </Route>

                 <Route path="*">
                  <NoMatchPage />
                </Route>
              </React.Suspense>
            </MainLayout>
          </Switch>
        </Router>
      // </div>
    );
  }
}

export default App;