import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import * as dotenv from 'dotenv';
import { Provider } from "react-redux";
import reducers from "./reducers";
import apiMiddleware from "./middleware/api";
import {createStore, applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as actionCreators from './actions/counter'; 

dotenv.config();


const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });


const store = createStore(reducers, composeEnhancers(
  applyMiddleware(apiMiddleware),
));
// const store = createStore(reducers, composeWithDevTools(
//   applyMiddleware(apiMiddleware),
// ));


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
	    <Router basename={process.env.PUBLIC_URL}>
	      <App /> 
	    </Router>
	</Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
