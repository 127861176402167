//Contact
export const CREATE_CONTACT = "CREATE_CONTACT";


//EXAM
export const FETCH_EXAM = "FETCH_EXAM";
export const FETCH_EXAMS = "FETCH_EXAMS";
export const SET_EXAM = "SET_EXAM";
export const SET_EXAMS = "SET_EXAMS";


//VIEW
export const ADD_VIEW = "ADD_VIEW";

//COURSES
export const FETCH_COURSES = "FETCH_COURSES";
export const SET_COURSES = "SET_COURSES";


//QUESTION
export const REPORT_QUESTION = "REPORT_QUESTION";

export const CHECKOUT = "CHECKOUT";


//RESULT 
export const SAVE_RESULT = "SAVE_RESULT"

//API
export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";


//COUNTER
export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';