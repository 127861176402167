import React from 'react';
import './Toolbar.css';
import {Link} from "react-router-dom";

const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
        <Link to="/" style={{color: "black", textDecoration: "none"}}>
          <div className="toolbar__logo">
            GEERTDEPONT
          </div>
        </Link>
        <div className="spacer" />
        <div className="toolbar_navigation-items">
            <ul>
                <li>
                    <ul className="social-items">
                      <li className="si"> 
                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/GeertcdePont">
                          <div className="facebook"></div>
                        </a>
                      </li>
                      <li className="si"> 
                        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/geertdepont/">
                          <div className="instagram"></div>
                        </a>
                      </li>
                      <li className="si"> 
                        <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/geert-de-pont-5362b914b/">
                          <div className="linkedin"></div>
                        </a>
                      </li>
                      <li className="si"> 
                        <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCiykEPx7sWdKBS-uT7z2kSw">
                          <div className="youtube"></div>
                        </a>
                      </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
  </header>
);

export default toolbar;
