import {
    SET_COURSES,
    FETCH_COURSES,
    API_START,
    API_END,
    API_ERROR
} from "../actions/types";


export default function (state = {}, action) {
    switch (action.type) {
        case SET_COURSES:
            return { data: action.payload };
        case API_START:
            if (action.payload === FETCH_COURSES) {
                return {
                    ...state,
                    isLoadingData: true
                };
            }
        // eslint-disable-next-line
        case API_END:
            if (action.payload === FETCH_COURSES) {
                return {
                    ...state,
                    isLoadingData: false
                };
            }
            return state;
        // eslint-disable-next-line
        case API_ERROR:
            if (action.payload === FETCH_COURSES) {
                return {
                    ...state,
                    isLoadingData: false,
                    error: action.error.response 
                };
            }
        // eslint-disable-next-line
        default:
            return state;
    }
}
