import { INCREMENT_COUNTER, DECREMENT_COUNTER} from "./types";

let t;

export function increment() {
  return {
    type: INCREMENT_COUNTER
  };
}

export function decrement() {
  return {
    type: DECREMENT_COUNTER
  };
}

export function autoIncrement(delay = 10) {
  return dispatch => {
    if (t) {
      clearInterval(t);
      t = undefined;
      return;
    }
    t = setInterval(() => {
      dispatch(increment());
    }, delay);
  };
}

export function incrementAsync(delay = 1000) {
  return dispatch => {
    setTimeout(() => {
      dispatch(increment());
    }, delay);
  };
}